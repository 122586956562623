//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: [],
    data () {
        return {
            model: '',
            syncText: '',
            data: [{"id":"100","text":"签字笔","children":[{"id":"100104","text":"记号笔","children":null},{"id":"100100","text":"0.5签字笔","children":[{"id":"100100101","text":"222","children":null}]},{"id":"100101","text":"0.2签字笔","children":null},{"id":"100102","text":"签字笔","children":null}]},{"id":"112","text":"vvvv","children":null},{"id":"138","text":"11111","children":null},{"id":"139","text":"11232323","children":null},{"id":"103","text":"档案盒","children":[{"id":"103102","text":"11","children":null}]},{"id":"204","text":"圆珠笔","children":null}]
        }
    },
    methods: {
        changeHandler(e) {
            console.log(e)
        }
    },
    mounted: function() {
        
    }
}
